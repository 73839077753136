<template>
  <b-row class="justify-content-center">
    <b-col cols="2">
      <div id="pre-loading-bg">
        <div class="loading-logo">
          <!-- <img
            :src="pageData.favicon"
            alt="Logo"
          > -->
        </div>
        <div class="loading">
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      pageData: JSON.parse(LocalStorageService.get('pageData')) || '',
    }
  },
}
</script>
