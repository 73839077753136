<template>
  <b-modal
    v-model="isModalShow"
    no-close-on-backdrop
    :title="modalTitle"
    size="lg"
    hide-footer
  >
    <div :style="isLoadingAudit ? { paddingBottom:'60px'} : { padding:''}">
      <preloader
        v-if="isLoadingAudit"
      />
      <activity-timeline
        v-if="!isLoadingAudit"
        :rows="audits"
        :total-records="auditTotal"
        :server-props-params="auditServerParams"
        :on-column-filter-server="onColumnFilterAuditServer"
        :refresh-grid="onRefreshAudit"
      />
    </div>
  </b-modal>
</template>
<script>
import { ref } from '@vue/composition-api'
import ActivityTimeline from './components/ActivityTimeline.vue'
import Preloader from '../components/preloader/preloader.vue'
import useActivityLog from '@/@service/hooks/useActivityLog'

import {
  VBModal,
} from 'bootstrap-vue'

export default {
  name: 'ActivityModal',
  components: {
    Preloader,
    ActivityTimeline,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Activity log ',
    },
    tag: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const {
      tag,
    } = props

    const isModalShow = ref(false)

    const {
      auditServerParams, isLoadingAudit, audits, auditTotal, getGridData, auditTag,
    } = useActivityLog()

    auditTag.value = tag

    const modalShow = () => {
      isModalShow.value = true
      isLoadingAudit.value = true
      getGridData()
    }

    const onRefreshAudit = () => {
      isLoadingAudit.value = true
      auditServerParams.value = {
        columnFilters: {},
        dateRange: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      }
      getGridData()
    }

    const onColumnFilterAuditServer = params => {
      auditServerParams.value = params
      if (JSON.stringify(auditServerParams.value) === JSON.stringify(params)) {
        getGridData()
      }
    }

    return {
      isLoadingAudit,
      audits,
      auditTotal,
      auditServerParams,
      onColumnFilterAuditServer,
      onRefreshAudit,
      modalShow,
      isModalShow,
      getGridData,
    }
  },
}
</script>
