/* eslint-disable no-unused-vars */
<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Invoice Print Setting
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <!-- add button -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          v-b-modal.coa-modal
          variant="outline-primary"
          @click.prevent="addFeeGroupingFunc"
        >
          Add Fee Grouping
        </b-button>
      </b-form-group>
    </div>

    <!-- grouping modal  -->
    <b-modal
      id="add-fee-grouping"
      size="lg"
      title="Add Fee Grouping"
      ok-title="Apply"
      ok-variant="outline-success"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok.prevent="addFeeGrouping"
    >
      <b-form @submit.prevent>
        <div>
          <!-- income  -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Type Name"
                invalid-feedback="Type Name is required"
                :state="nameState"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="feeGrouping.name"
                  :state="nameState"
                  placeholder="Type Name"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Priority"
                invalid-feedback="priority is required"
                :state="priorityState"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="feeGrouping.priority"
                  :state="priorityState"
                  placeholder="Priority"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Fee Head List"
                invalid-feedback="Fee Head is required"
                :state="coa_idsState"
                label-cols-md="4"
              >
                <b-form-checkbox-group
                  v-model="feeGrouping.coa_ids"
                  :options="coa_idsOptions"
                  :aria-describedby="ariaDescribedby"
                  text-field="gl_name"
                  value-field="id"
                  name="flavour-2a"

                  :state="coa_idsState"
                  required
                  class="col-md-6"
                />
              </b-form-group>
            </b-col>
          </b-row>

        </div>
      </b-form>

    </b-modal>
    <!-- grouping modal  -->

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-secondary"
            >view</b-button>
            <b-button
              class="mr-1 px-1"
              variant="outline-primary"
              @click="editFeeGrouping(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteFeeGrouping(props.row)"
            >Delete</b-button>
          </span>
        </span>
      </template>

    </vue-good-table>

    <div class="mt-3">
      <b-form
        @submit.prevent
        @submit="onSubmit"
      >
        <div>

          <!-- signature  -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Signature"
                invalid-feedback="Please select an employee"
                :state="signatureState"
                label-cols-md="3"
              >
                <div class="d-flex justify-content-between">
                  <vue-autosuggest
                    v-model="employee_name"
                    :suggestions="searchEmployees"
                    :limit="10"
                    :state="signatureState"
                    :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search employee name'}"
                    class="col-md-6 pl-0 pr-0"
                    @selected="onSelected"
                    @input="onInputChange"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item.full_name }}</span>
                    </template>
                  </vue-autosuggest>
                  <template
                    v-if="mainData.signature"
                    class="col-md-6 pl-1"
                  >
                    <b-img
                      fluid
                      :src="require('@/assets/images/signature/signature.jpg')"
                      alt="signature"
                    />

                  </template>
                </div>

              </b-form-group>

            </b-col>
          </b-row>

          <!-- Note New-->
          <b-row class="mb-2">
            <b-col
              cols="11"
              class="mb-md-0 mb-2"
            >
              <b-row>
                <b-col cols="3">
                  Notes
                </b-col>
                <b-col
                  cols="9"
                  class="p-0"
                >
                  <b-row
                    v-for="(noteConfig, index) in noteConfigs"
                    :id="noteConfig.id"
                    :key="noteConfig.id"
                    ref="noteConfigRow"
                  >
                    <b-col cols="10">
                      <b-form-input
                        v-model="noteConfig.notes"
                        placeholder="Note is Required"
                        class="mb-1 ml-1"
                      />
                    </b-col>
                    <b-col cols="2">
                      <b-button
                        v-if="noteConfig.id == 1"
                        class="mr-1"
                        variant="primary"
                        @click="repeatNoteConfig"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                      <b-button
                        v-if="noteConfig.id != 1"
                        variant="outline-danger"
                        @click="removeNoteConfig(index)"
                      >
                        <feather-icon icon="MinusIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Note New-->

          <!-- show printed by  -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2 mt-2"
            >
              <b-form-group
                label="Show Printed By"
                invalid-feedback="Please switch on"
                :state="printedByState"
                label-cols-md="3"
              >
                <b-form-checkbox
                  v-model="mainData.printedBy"
                  name="check-button"
                  switch
                  :state="printedByState"
                  size="lg"
                  @change="printedByCheck"
                />
                <div
                  v-if="mainData.printedBy"
                  class="mt-2"
                >
                  <b-form-radio-group
                    v-model="mainData.employee"
                    class="mt-0"
                    :options="EmployeeOptions"
                    :state="employeeState"
                    name="radio-inline1"
                  />
                  <b-form-invalid-feedback :state="employeeState">
                    Employee Data is required
                  </b-form-invalid-feedback>
                  <b-form-valid-feedback :state="employeeState" />
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- show printed date  -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Show Printed Date"
                invalid-feedback="printed Date is required"
                :state="printedDateState"
                label-cols-md="3"
              >
                <b-form-checkbox
                  v-model="mainData.printedDate"
                  name="check-button"
                  switch
                  :state="printedDateState"
                  size="lg"
                  @change="printedDateCheck"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- show powered by  -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Show Powered By"
                invalid-feedback="Please switch on"
                :state="poweredByState"
                label-cols-md="3"
              >
                <b-form-checkbox
                  v-model="mainData.poweredBy"
                  name="check-button"
                  switch
                  size="lg"
                  :state="poweredByState"
                  @change="poweredByCheck"
                />
                <!-- <div
                  v-if="mainData.poweredBy"
                  class="mt-2"
                >
                  <b-form-group
                    invalid-feedback="Powered Person is required"
                    :state="poweredPersonState"
                  >
                    <b-form-input
                      v-model="mainData.poweredPerson"
                      :state="poweredPersonState"
                      placeholder="Powered Person"
                      class="col-md-6"
                    />
                  </b-form-group>
                </div> -->
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Buttons  -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group label-cols-md="3">

                <b-button
                  type="submit"
                  variant="primary"
                >
                  Submit
                </b-button>
              </b-form-group>

            </b-col>
          </b-row>

        </div>
      </b-form>
    </div>
    <!--    Activity Model-->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  // BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  // BFormSelect,
  BRow,
  BCol,
  VBModal,
  BButton,
  BFormCheckboxGroup,
  // BFormTextarea,
  BFormCheckbox,
  BFormRadioGroup,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BImg,
  BLink,
  // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
// import vSelect from 'vue-select'
import store from '@/store/index'
import { VueAutosuggest } from 'vue-autosuggest'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BCard,
    VueGoodTable,
    // BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    // BFormSelect,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    // vSelect,
    BButton,
    VueAutosuggest,
    // BDropdown,
    // BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // BFormTextarea,
    BFormRadioGroup,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BImg,
    BLink,
    ActivityModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {

      /*
      *add fee grouping data
      */
      feeGrouping: {
        name: null,
        priority: null,
        coa_ids: [],

        coa_labels: '',
      },
      test_coa_ids: [],
      coa_idsOptions: [],
      tempCoa_idsOptions: [],

      nameState: null,
      priorityState: null,
      coa_idsState: null,

      feeGroupingData: [],
      tempFeeGroupingData: [],
      /*
      *add fee grouping data
      */

      /*
    * main data
    */

      mainData: {
        signature: null,
        note: null,

        printedBy: false,
        employee: null,

        printedDate: false,

        poweredBy: false,
        poweredPerson: null,
      },
      employee_name: null,
      EmployeeOptions: [
        { text: 'Employee ID', value: 1 },
        { text: 'Employee Name', value: 2 },
      ],

      signatureState: null,
      noteState: null,
      employeeState: null,
      printedByState: null,
      printedDateState: null,
      poweredByState: null,
      poweredPersonState: null,
      /*
    * main data
    */
      pageLength: 10,
      dir: false,

      columns: [
        {
          label: 'Group Name',
          field: 'name',
        },
        {
          label: 'Priority',
          field: 'priority',
        },
        {
          label: 'Fees',
          field: 'coa_labels',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],

      updateId: null,

      employees: [],
      searchEmployees: [],

      searchStudents: [],
      studentName: '',
      studentIDStr: '',
      isEdit: null,
      indexId: null,

      coaLabels: '',

      noteConfigs: [{
        id: 1,
        notes: '',
      }],
      nextNoteConfigId: 1,

      // Audit Activity
      tag: 'invoice-print-setting',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    // 'feeGrouping.coa_ids': function (oldVal, newVal) {
    //   console.log(oldVal)
    //   console.log(newVal)
    // },
  },
  created() {
  },
  mounted() {
    this.getCoaFee()
    this.read()
  },
  methods: {
    // get data from apis
    getCoaFee() {
      this.$http.get(`${window.apiUrl}accounting/invoice-print-settings/fee-name`)
        .then(res => {
          this.tempCoa_idsOptions = res.data.data
          this.coa_idsOptions = res.data.data
        })
    },

    // employee auto suggest
    onSelected(option) {
      this.mainData.signature = option.item.employee_id
      this.employee_name = `${option.item.full_name}`
    },
    onInputChange(search) {
      if (!search) {
        this.searchEmployees = []
        this.mainData.signature = null
        return
      }
      if (search.length && search.length >= 4) {
        this.$http.post(`${window.apiUrl}accounting/invoice-print-settings/employee-list`, {
          employeeName: search,
        })
          .then(res => {
            if (res.status) {
              if (res.data.status) {
                this.searchEmployees = [{
                  data: res.data.data,
                }]
              } else {
                this.searchEmployees = []
              }
            }
          })
      }
    },

    // switched data change
    printedByCheck() {
      this.printedByState = null
      this.employeeState = null
      if (this.mainData.printedBy === false) {
        this.mainData.employee = null
      }
    },

    printedDateCheck() {
      this.printedDateState = null
    },

    poweredByCheck() {
      this.poweredByState = null
      /*
      * this code is for powere by person name
      */
    },

    // fee grouping operations
    coaIdsIteration(tempFeeGroupingData, tempCoaIdsOptions) {
      // eslint-disable-next-line camelcase
      const coa_idsArr = []
      for (let i = 0; i < tempFeeGroupingData.length; i += 1) {
        coa_idsArr.push(tempFeeGroupingData[i].coa_ids)
      }

      let usedIds = []
      for (let i = 0; i < coa_idsArr.length; i += 1) {
        usedIds = usedIds.concat(coa_idsArr[i])
      }

      const finalCoaIdLists = []

      for (let i = 0; i < tempCoaIdsOptions.length; i += 1) {
        if (!usedIds.includes(tempCoaIdsOptions[i].id)) {
          finalCoaIdLists.push(tempCoaIdsOptions[i])
        }
      }
      return finalCoaIdLists
    },
    groupReset() {
      this.feeGrouping = {
        name: null,
        priority: null,
        coa_ids: [],
      }

      this.nameState = null
      this.priorityState = null
      this.coa_idsState = null
    },
    groupingFormValidation() {
      this.nameState = null
      this.priorityState = null
      this.coa_idsState = null
      // if (this.feeGrouping.name === null || this.feeGrouping.name === '') {
      //   this.nameState = false
      //   return false
      // }
      if (this.feeGrouping.priority === null || this.feeGrouping.priority === '') {
        this.priorityState = false
        return false
      }
      if (this.feeGrouping.coa_ids.length < 1) {
        this.coa_idsState = false
        return false
      }
      return true
    },

    addFeeGroupingFunc() {
      this.groupReset()
      this.coa_idsOptions = this.coaIdsIteration(this.tempFeeGroupingData, this.tempCoa_idsOptions)
      this.$bvModal.show('add-fee-grouping')
    },
    addFeeGrouping() {
      if (!this.groupingFormValidation()) {
        return
      }
      this.$swal({
        title: 'Are you sure to add data ?',
        text: 'You have to finally submit data to save the settings.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Yes, Apply',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // creating coa labels
          // eslint-disable-next-line no-unused-vars
          let count = this.feeGrouping.coa_ids.length
          for (let i = 0; i < this.tempCoa_idsOptions.length; i += 1) {
            if (this.feeGrouping.coa_ids.includes(this.tempCoa_idsOptions[i].id)) {
              this.coaLabels += `${this.tempCoa_idsOptions[i].gl_name}`
              count -= 1
              if (count > 0) {
                this.coaLabels += ' - '
              }
            }
          }

          if (this.isEdit) { // for edit operation
            const obj = {
              name: this.feeGrouping.name || null,
              priority: this.feeGrouping.priority,
              coa_ids: this.feeGrouping.coa_ids,
              coa_labels: this.coaLabels,
            }
            this.feeGroupingData.splice(this.indexId, 1, obj)
          } else {
            // eslint-disable-next-line no-unused-vars
            this.feeGroupingData.push({
              name: this.feeGrouping.name || null,
              priority: this.feeGrouping.priority,
              coa_ids: this.feeGrouping.coa_ids,
              coa_labels: this.coaLabels,
            })
            this.rows = this.feeGroupingData
            this.tempFeeGroupingData = this.feeGroupingData
          }
          this.coa_idsOptions = this.coaIdsIteration(this.tempFeeGroupingData, this.tempCoa_idsOptions)
          this.isEdit = null
          this.indexId = null
          this.coaLabels = ''
          this.groupReset()
          this.$bvModal.hide('add-fee-grouping')
        }
      })
    },

    editFeeGrouping(rowData) {
      this.feeGrouping.name = rowData.name || null
      this.feeGrouping.priority = rowData.priority
      this.feeGrouping.coa_ids = rowData.coa_ids
      this.coa_idsOptions = this.coaIdsIteration(this.tempFeeGroupingData, this.tempCoa_idsOptions)
      for (let i = 0; i < this.tempCoa_idsOptions.length; i += 1) {
        if (rowData.coa_ids.includes(this.tempCoa_idsOptions[i].id)) {
          this.coa_idsOptions.push(this.tempCoa_idsOptions[i])
        }
      }
      this.$bvModal.show('add-fee-grouping')
      this.isEdit = true
      this.indexId = rowData.originalIndex
    },
    deleteFeeGrouping(rowData) {
      this.$swal({
        title: 'Are you sure to remove?',
        text: 'You have to submit final data to save the settings.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.coa_idsOptions = this.coaIdsIteration(this.tempFeeGroupingData, this.tempCoa_idsOptions)
          for (let i = 0; i < this.tempCoa_idsOptions.length; i += 1) {
            if (rowData.coa_ids.includes(this.tempCoa_idsOptions[i].id)) {
              this.coa_idsOptions.push(this.tempCoa_idsOptions[i])
            }
          }
          // this.coa_idsOptions
          this.feeGroupingData.splice(rowData.originalIndex, 1)
        }
      })
    },

    // main from
    validation() {
      this.signatureState = null
      this.noteState = null
      this.employeeState = null

      if (this.mainData.printedBy === true) {
        this.printedByState = null
        if (this.mainData.employee === '' || this.mainData.employee === null) {
          this.employeeState = false
          return false
        }
      }

      /*
      * this part is for powered by person if needed
      */

      return true
    },
    arrayMatch(arr1, arr2) {
      const arr = [] // Array to contain match elements
      for (let i = 0; i < arr1.length; i += 1) {
        for (let j = 0; j < arr2.length; j += 1) {
          if (arr1[i] === arr2[j]) { // If element is in both the arrays
            arr.push(arr1[i]) // Push to arr array
          }
        }
      }
      return arr
    },
    onSubmit() {
      if (!this.validation()) {
        return
      }

      for (let i = 0; i < this.feeGroupingData.length; i += 1) {
        delete this.feeGroupingData[i].coa_labels
        delete this.feeGroupingData[i].coa_lists
      }

      if (this.feeGroupingData.length > 1) {
        // eslint-disable-next-line no-unused-vars
        let arr = []
        for (let i = 0; i < this.feeGroupingData.length; i += 1) {
          for (let j = i + 1; j <= this.feeGroupingData.length - 1; j += 1) {
            arr = this.arrayMatch(this.feeGroupingData[i].coa_ids, this.feeGroupingData[j].coa_ids)
            if (arr.length > 0) {
              FemsToastrService.error('Duplicated Fee Head can not be inputted')
              return
            }
          }
        }
      }

      const invoicePrintSetting = {
        add_fee_group: this.feeGroupingData,
        signature_employee_id: this.mainData.signature,
        note: JSON.stringify(this.noteConfigs),
        printed_by: this.mainData.printedBy,
        employee_code: this.mainData.employee,
        printed_date: this.mainData.printedDate,
        powered_by: this.mainData.poweredBy,
      }

      this.$http.post(`${window.apiUrl}accounting/invoice-print-settings/store`, {
        invoice_print_setting: invoicePrintSetting,
      })
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              this.read()
              FemsToastrService.success(res?.data.message)
            } else {
              FemsToastrService.error(res?.data.message)
            }
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    read() {
      this.$http
        .get(`${window.apiUrl}accounting/invoice-print-settings/read`)
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              this.tempFeeGroupingData = res.data.data.add_fee_group ? res.data.data.add_fee_group : []
              this.feeGroupingData = res.data.data.add_fee_group ? res.data.data.add_fee_group : []
              this.rows = res.data.data.add_fee_group ? res.data.data.add_fee_group : []
              // this.mainData.note = res.data.data.note
              this.noteConfigs = res.data.data.note ? JSON.parse(res.data.data.note) : this.noteConfigs
              this.mainData.printedBy = res.data.data.printed_by ? res.data.data.printed_by : false
              this.mainData.employee = res.data.data.employee_code ? res.data.data.employee_code : null
              this.mainData.printedDate = res.data.data.printed_date ? res.data.data.printed_date : false
              this.mainData.poweredBy = res.data.data.powered_by ? res.data.data.powered_by : false
              this.mainData.signature = res.data.data.signature_employee_id ? res.data.data.signature_employee_id : null
              this.employee_name = res.data.data.employee ? res.data.data.employee.full_name : null
            }
          }
        })
    },

    repeatNoteConfig() {
      let errorMessage = null
      // eslint-disable-next-line no-unused-vars
      let error = false
      const fineConfig = {}
      this.noteConfigs.forEach(item => {
        if (!item.notes || item.notes === null || item.notes === '') {
          error = true
          errorMessage = 'Please specify Note!'
        } else {
          fineConfig[item.notes] = item.notes
        }
      })

      if (errorMessage) {
        FemsToastrService.error(errorMessage)
        return
      }
      FemsToastrService.success('Note Added')

      this.noteConfigs.push({
        id: this.nextNoteConfigId += 1,
      })
      // this.noteConfigData.push({
      //   amount: this.noteConfig.amount,
      //   dayWeekTime: this.noteConfig.dayWeekTime,
      //   dayWeekValue: this.noteConfig.dayWeekValue,

      // })
    },
    removeNoteConfig(index) {
      this.noteConfigs.splice(index, 1)
    },

    // Audit ACtivity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
.editor-height {
  height: 200px;
}
</style>
