<template>
  <div :style="{padding:'20px'}">
    <div class="row">
      <div class="col-12">
        <b-form
          @submit.prevent="onFilterDateRange"
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
              sm="6"
            >
              <label for="datepicker-buttons">Date From</label>
              <b-form-group
                :state="dateFromState"
                invalid-feedback="Date from"
              >
                <b-form-datepicker
                  v-model="searchForm.dateFrom"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  placeholder="DD/MM/YYYY"
                  reset-button
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
              sm="6"
            >
              <label for="datepicker-buttons">Date To</label>
              <b-form-group
                :state="dateToState"
                invalid-feedback="Date To"
              >
                <b-form-datepicker
                  v-model="searchForm.dateTo"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  placeholder="DD/MM/YYYY"
                  reset-button
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
              md="3"
              sm="6"
            >
              <label for="datepicker-buttons">Event</label>
              <b-form-group
                :state="eventState"
                invalid-feedback="Event"
              >
                <v-select
                  v-model="event"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="eventList"
                  :disabled="!eventList.length"
                  :reduce="(val) => val.value"
                  placeholder="Select Event"
                  @input="onFilterEvent"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
              sm="6"
              class="mt-2"
            >
              <b-form-group>
                <b-button
                  variant="outline-primary"
                  type="submit"
                  class="btn-sm"
                >
                  Filter
                </b-button>
                <b-button
                  variant="outline-danger mx-1"
                  class="btn-sm"
                  type="button"
                  @click="onClearForm"
                >
                  Reset
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <app-timeline>
      <app-timeline-item
        v-for="(row,index) in rows"
        :key="index"
        :variant="row.event==='created' ? 'secondary' : row.event==='deleted' ? 'danger' :row.event==='updated' ? 'success' : '' "
      >

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ capitalizeFirstLetter(row.event) }}</h6>
          <small>{{ showLocalDateTime(row.created_at) }}</small>
        </div>
        <p>{{ row.message }}</p>
        <b-media>
          <template #aside>
            <b-avatar :src="row.gender===1 ? require('@/assets/images/avatars/no-image-male.jpg') : require('@/assets/images/avatars/11-small.png')" />
          </template>
          <h6 :style="{lineHeight:'35px'}">
            {{ row.name }} ({{ row.user_username }})
          </h6>
          <!-- <p>CEO of Infibeam</p> -->
        </b-media>
      </app-timeline-item>
    </app-timeline>
    <!--  pagination -->
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-0 mt-1">
        <span class="text-nowrap"> Page Length </span>
        <b-form-select
          v-model="pageLength"
          :options="perPageOptions"
          class="mx-1"
          @input="(value) =>onPerPageChange(value)"
        />
        <span class="text-nowrap"> Showing {{ auditServerParams.page===1 ? 1 : pageLength*(auditServerParams.page-1) }} to {{ pageLength*auditServerParams.page > totalRecords ? totalRecords : pageLength*auditServerParams.page }}  of {{ totalRecords }} entries </span>
      </div>
      <div class="d-flex">
        <div class="mr-2">
          <b-pagination
            :value="auditServerParams.page"
            :total-rows="totalRecords"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="(value) =>onPageChange(value)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <div
          slot="table-actions"
          class="mt-1"
        >
          <b-button
            v-if="refreshGrid"
            class="btn-sm mr-1 px-1 "
            variant="outline-primary"
            @click="refreshGrid()"
          >
            <RefreshCwIcon />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormDatepicker,
  BAvatar, BMedia,
  BPagination,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vSelect from 'vue-select'

// eslint-disable-next-line no-unused-vars
import { showLocalDateTime, capitalizeFirstLetter } from '@/@service/utils/utils'

import { RefreshCwIcon } from 'vue-feather-icons'
import { ref } from '@vue/composition-api'

import useActivityLog from '@/@service/hooks/useActivityLog'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BPagination,
    BFormSelect,
    BButton,
    RefreshCwIcon,
    vSelect,
  },
  props: {
    rows: { type: Array, default: () => [] },
    totalRecords: { type: Number, default: 0 },
    refreshGrid: { type: Function, default: () => {} },
    onColumnFilterServer: { type: Function, default: () => {} },
    onRefresh: { type: Function, default: () => {} },
  },
  setup(props) {
    const {
      onColumnFilterServer,
    } = props

    const {
      auditServerParams,
    } = useActivityLog()

    const pageLength = auditServerParams.value.page_length || 10
    const perPageOptions = ref(['5', '10', '20', '30', '40', '50'])
    const dateFromState = ref(null)
    const dateToState = ref(null)
    const eventState = ref(null)
    const event = ref('')
    const auditTag = ref('')
    const searchForm = ref({
      dateFrom: '',
      dateTo: '',
    })
    const eventList = [
      { text: 'All Event', value: '' },
      { text: 'Created', value: 'created' },
      { text: 'Updated', value: 'updated' },
      { text: 'Deleted', value: 'deleted' },
    ]

    const updateParams = newProps => {
      auditServerParams.value = { ...auditServerParams.value, ...newProps }
    }

    const onFilterServer = () => {
      onColumnFilterServer(auditServerParams.value)
    }
    const onPageChange = params => {
      updateParams({ page: params })
      onFilterServer()
    }

    const onPerPageChange = params => {
      updateParams({ perPage: Number(params) })
      onFilterServer()
    }

    const onFilterEvent = () => {
      updateParams({ columnFilters: { event } })
      onFilterServer()
    }

    const onFilterDateRange = () => {
      updateParams({ dateRange: searchForm.value })
      onFilterServer()
    }

    const onClearForm = () => {
      searchForm.value.dateFrom = ''
      searchForm.value.dateTo = ''
      event.value = ''
    }

    return {

      pageLength,
      perPageOptions,
      dateFromState,
      dateToState,
      eventState,
      event,
      auditTag,
      searchForm,
      eventList,
      auditServerParams,
      updateParams,
      onPageChange,
      onPerPageChange,
      onFilterEvent,
      onFilterDateRange,
      onClearForm,
      onFilterServer,
    }
  },
}
</script>
