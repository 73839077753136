import { ref } from '@vue/composition-api'

import AuditService from '@/@service/api/Audit.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default function useActivityLog() {
  const isLoadingAudit = ref(false)
  const audits = ref([])
  const auditTotal = ref(0)
  const pageLength = ref(10)
  const perPageOptions = ref(['5', '10', '20', '30', '40', '50'])
  const dateFromState = ref(null)
  const dateToState = ref(null)
  const eventState = ref(null)
  const event = ref('')
  const auditTag = ref('')
  const searchForm = ref({
    dateFrom: '',
    dateTo: '',
  })

  const auditServerParams = ref({
    columnFilters: {},
    dateRange: {},
    sort: [
      {
        field: '',
        type: '',
      },
    ],
    page: 1,
    perPage: 10,
  })

  const updateParams = newProps => {
    auditServerParams.value = { ...auditServerParams.value, ...newProps }
  }

  const getGridData = () => {
    AuditService.getAllByTag(auditTag.value, auditServerParams.value)
      .then(res => {
        const response = res.data
        isLoadingAudit.value = false
        audits.value = response.data
        auditTotal.value = response.total
      })
      .catch(() => {
        isLoadingAudit.value = false
        FemsToastrService.error('Something went wrong')
      })
  }

  const onPageChange = params => {
    updateParams({ page: params })
    getGridData()
  }

  const onPerPageChange = params => {
    updateParams({ perPage: Number(params) })
    getGridData()
  }

  const onFilterEvent = () => {
    updateParams({ columnFilters: { event: event.value } })
    getGridData()
  }

  const onFilterDateRange = () => {
    updateParams({ dateRange: searchForm.value })
    getGridData()
  }

  const onClearForm = () => {
    searchForm.dateFrom.value = ''
    searchForm.dateTo.value = ''
    event.value = ''
  }

  return {
    updateParams,
    getGridData,
    audits,
    auditTotal,
    isLoadingAudit,
    pageLength,
    perPageOptions,
    dateFromState,
    dateToState,
    eventState,
    event,
    searchForm,
    auditServerParams,
    onPageChange,
    onPerPageChange,
    onFilterEvent,
    onFilterDateRange,
    onClearForm,
    auditTag,

  }
}
