// eslint-disable-next-line import/prefer-default-export

import Vue from 'vue'

/**
 * Local date formatting
 * Only Date
 * @param dateTime
 */
export const showOnlyDate = dateTime => {
  const fullDate = new Date(dateTime)
  let twoDigitMonth = `${fullDate.getMonth() + 1}`
  if (twoDigitMonth.length === 1) { twoDigitMonth = `0${twoDigitMonth}` }
  let twoDigitDate = `${fullDate.getDate()}`
  if (twoDigitDate.length === 1) { twoDigitDate = `0${twoDigitDate}` }
  return `${twoDigitDate}-${twoDigitMonth}-${fullDate.getFullYear()}`
}

/**
 * DB date formatting
 * Only Date
 * @param dateTime
 */
export const showOnlyDateDBFormat = dateTime => {
  const fullDate = new Date(dateTime)
  let twoDigitMonth = `${fullDate.getMonth() + 1}`
  if (twoDigitMonth.length === 1) { twoDigitMonth = `0${twoDigitMonth}` }
  let twoDigitDate = `${fullDate.getDate()}`
  if (twoDigitDate.length === 1) { twoDigitDate = `0${twoDigitDate}` }
  return `${fullDate.getFullYear()}-${twoDigitMonth}-${twoDigitDate}`
}

/**
 * Local date time formatting
 * @param dateTime
 */
export const showLocalDateTime = dateTime => {
  const fullDate = new Date(dateTime)
  const time = fullDate.toLocaleTimeString('en-BN', { timeZone: 'Asia/Dhaka' })
  return `${showOnlyDate(dateTime)} ${time}`
}

/**
 * DB date time formatting
 * @param dateTime
 */
export const showLocalDateTimeDBFormat = dateTime => {
  const fullDate = new Date(dateTime)
  const time = fullDate.toLocaleTimeString('en-GB')
  return `${showOnlyDateDBFormat(dateTime)} ${time}`
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export const userAbility = isAdmin => {
  if (process.env.VUE_APP_ENV === 'production') {
    if (isAdmin) {
      return [
        {
          action: 'dashboard',
          subject: 'dashboard',
        },
        {
          action: 'accounting',
          subject: 'accounting',
        },
        {
          action: 'payment-collection',
          subject: 'payment-collection',
        },
        {
          action: 'student-concessions',
          subject: 'student-concessions',
        },
        {
          action: 'vouchers',
          subject: 'vouchers',
        },
        {
          action: 'fintech',
          subject: 'fintech',
        },
        {
          action: 'fintech-menu',
          subject: 'fintech-menu',
        },
        {
          action: 'fintech-service',
          subject: 'fintech-service',
        },
        {
          action: 'fintech-service-provider',
          subject: 'fintech-service-provider',
        },
        {
          action: 'merchant-settlement-account',
          subject: 'merchant-settlement-account',
        },
        {
          action: 'fintech-institute-service',
          subject: 'fintech-institute-service',
        },
        {
          action: 'fintech-merchant',
          subject: 'fintech-merchant',
        },
        {
          action: 'ins-fintech',
          subject: 'ins-fintech',
        },
        {
          action: 'coa',
          subject: 'coa',
        },
        {
          action: 'coa-config',
          subject: 'coa-config',
        },
        {
          action: 'coa-mapping',
          subject: 'coa-mapping',
        },
        {
          action: 'std-fee-config',
          subject: 'std-fee-config',
        },
        {
          action: 'payment-cycle-config',
          subject: 'payment-cycle-config',
        },
        {
          action: 'advance-override',
          subject: 'advance-override',
        },
        {
          action: 'group-invoice',
          subject: 'group-invoice',
        },
        {
          action: 'due-report',
          subject: 'due-report',
        },
        {
          action: 'paid-report',
          subject: 'paid-report',
        },
        {
          action: 'student-ledger',
          subject: 'student-ledger',
        },
        {
          action: 'head-wise-ledger',
          subject: 'head-wise-ledger',
        },
        {
          action: 'head-wise-summaries',
          subject: 'head-wise-summaries',
        },
        {
          action: 'invoice-print-settings',
          subject: 'invoice-print-settings',
        },
        {
          action: 'sms-notification',
          subject: 'sms-notification',
        },
        {
          action: 'resourceSharing',
          subject: 'resourceSharing',
        },
        {
          action: 'customResource',
          subject: 'customResource',
        },
        {

          action: 'oisacl-roles',
          subject: 'oisacl-roles',
        },
        {
          action: 'manage',
          subject: 'ACL',
        },
        {
          action: 'manage',
          subject: 'Auth',
        },

      ]
    }
    return [
      {
        action: 'dashboard',
        subject: 'dashboard',
      },
      {
        action: 'resourceSharing',
        subject: 'resourceSharing',
      },
      {
        action: 'read',
        subject: 'ACL',
      },
      {
        action: 'read',
        subject: 'Auth',
      },
    ]
  }
  return [
    {
      action: 'manage',
      subject: 'all',
    },
  ]
}

Vue.mixin({
  methods: {
    showLocalDateTime,
    showOnlyDate,
    capitalizeFirstLetter,
    userAbility,
    showOnlyDateDBFormat,
  },
})
